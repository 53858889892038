<template>
  <div class="etax-auth-page" style="width: 50%;margin: 0 auto;">
    <div class="enterprise-info">
      <p>名称：四川微融锐银企业管理有限公司</p>
      <p>税号：{{ creditCode }}</p>
    </div>
    <div class="auth-form">
      <van-form>
        <van-field name="checkboxGroup" label="查询身份">
          <template #input>
            <van-checkbox-group direction="horizontal">
              <van-checkbox name="1" shape="square">销项发票</van-checkbox>
              <van-checkbox name="2" shape="square">进项发票</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
      </van-form>
          <van-button
            block
            color="#7232dd"
            class="auth-btn"
            :loading="loginBtnLoading"
            @click="authHandle"
            >查询</van-button
          >
    </div>
    <van-popup
      v-model="popup.show"
      :closeable="false"
      :close-on-click-overlay="false"
      position="bottom"
      round
      class="popup-message"
    >
      {{ popup.message }}
    </van-popup>
  </div>
</template>

<script>
import { Popup, Row, Col, Field, Button, Dialog, Toast, CheckboxGroup, Checkbox } from "vant";
import ETaxApi from "../api/etax";

export default {
  name: "EtaxAuthStep2",
  components: {
    [Popup.name]: Popup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Button.name]: Button,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
  },
  props: {
    creditCode: String,
    sign: String,
  },
  data() {
    return {
      orderId: "",
      enterpriseName: "",
      agreementTips: [],
      legalPerson: {},
      popup: {
        show: false,
        message: "",
      },
      password: "",
      yzm: "wnsjhm",
      agreementCheck: true,
      yzmCountDown: 60,
      yzmCountDownTimer: null,
      yzmBtnLoading: false,
      loginBtnLoading: false,
    };
  },
  computed: {
    yzmBtnText() {
      if (this.yzmCountDown > 59) {
        return "发送验证码";
      } else {
        return `${this.yzmCountDown}s后重发`;
      }
    },
  },
  created() {},
  mounted() {
    // this.loadData();
  },
  methods: {
    loadData() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        className: "loading-toast",
      });
      ETaxApi.checkAuthLink(this.creditCode, this.sign)
        .then((res) => {
          this.enterpriseName = res.data.enterpriseName;
          this.creditCode = res.data.creditCode;
          this.legalPerson = res.data.legalPerson;
          this.agreementTips = res.data.agreementTips;
          this.orderId = res.data.orderId;
        })
        .finally(() => {
          Toast.clear();
        });
    },
    showPopup(message) {
      this.popup.show = true;
      this.popup.message = message;
    },
    forgotPws() {
      Dialog.alert({
        message: "请进入电子税务局修改密码",
      });
    },
    sendValidationCode() {
      if (!this.password) {
        Dialog.alert({ message: "请输入电子税务局登录密码" });
        return;
      }
      this.yzmBtnLoading = true;
      ETaxApi.sendSMS({
        bdgxId: this.legalPerson.bdgxId,
        bsrType: this.legalPerson.bsrType,
        nsrsbh: this.creditCode,
        password: this.password,
        multiple: this.legalPerson.multiple
      })
        .then(() => {
          this.yzmCountDown--;
          this.yzmCountDownTimer = setInterval(() => {
            if (this.yzmCountDown > 1) {
              this.yzmCountDown--;
            } else {
              clearInterval(this.yzmCountDownTimer);
              this.yzmCountDown = 60;
            }
          }, 1000);
          Toast.success("短信已发送");
        })
        .finally(() => {
          this.yzmBtnLoading = false;
        });
    },
    authHandle() {
      if (!this.password) {
        Dialog.alert({ message: "请输入电子税务局登录密码" });
        return;
      }
      if (!this.yzm) {
        Dialog.alert({ message: "请输入短信验证码" });
        return;
      }
      this.loginBtnLoading = true;
      ETaxApi.doLogin({
        bdgxId: this.legalPerson.bdgxId,
        bsrType: this.legalPerson.bsrType,
        nsrsbh: this.creditCode,
        nsrmc: this.enterpriseName,
        password: this.password,
        smsCode: this.yzm,
        orderId: this.orderId,
        agreementCheck: this.agreementCheck,
      })
        .then((res) => {
          this.orderId = res.data;
          this.showPopup("授权成功")
          this.loginBtnLoading = false;
        })
        .catch(() => {
          this.loginBtnLoading = false;
        });
    },
    toAgreementContentPage(id) {
      this.$router.push("/agreement/content/" + id);
    },
  },
};
</script>

<style scoped>
.etax-auth-page {
  font-size: 32px;
  line-height: 56px;
}
.enterprise-info {
  margin: 30px;
  padding: 30px;
  background-color: #eeeeee;
  border-radius: 15px;
}
.auth-form {
  margin: 30px;
}
.field-item {
  border: 1px solid #eeeeee;
}
.auth-form {
  margin-top: 50px;
  line-height: 64px;
}
.forgot-pwd-link {
  line-height: 80px;
  padding-left: 30px;
  color: #7232dd;
}
.send-yzm-btn,
.auth-btn {
  height: 80px;
}
.send-yzm-btn span,
.auth-btn span {
  line-height: 76px;
}
.agreement-wrapper {
  display: flex;
  align-items: flex-start;
}
.agreement-checkbox {
  padding: 10px;
}
.tax-auth-tips {
  max-width: 700px;
  margin: 100px auto 0 auto;
  background-color: #eee;
  padding: 20px;
}
.agreement-link {
  color: #7232dd;
}
.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>